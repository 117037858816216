import React, { useCallback, useEffect, useState } from 'react';
import { useGetSubscriptionQuery } from 'store/features/user-api';
import { Button, Flex, Loader, Message } from 'ui';
import DashboardFrame from '../DashboardFrame';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useCancelSubscriptionMutation } from 'store/features/user-api';
import { toast } from 'react-toastify';

const CancelHeading = styled.h2`
    margin-bottom: 0;
`;

const OptionalText = styled.p`
    font-size: 14px;
    opacity: 0.6;
    margin: 5px 0 20px 0;
`;

const CancelReason = styled.div`
    border: 1px solid #e0e0e0;
    margin: 10px 0;    

`;
const CancelReasonLabel = styled.label`
    display: block;
    cursor: pointer;
    padding: 15px 20px 15px 50px;

    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
    }

    &::after {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border: 1px solid #000;
        border-radius: 50%;

        background: #000;
    }
`;

const CancelReasonRadioGroup = styled.div`
    ${props => {
        if (props.$disabled) {
            return `
                opacity: 0.6;

                input, ${CancelReasonLabel} {
                    pointer-events: none;
                }
            `;
        }
    }}
`;
const CancelReasonSolutionWrap = styled.div`
    padding: 20px 15px 20px 50px;
    border-top: 1px solid #e0e0e0;
    display: none;

    p {
        margin: 0 0 20px 0;
    }
`;
const CancelReasonRadio = styled.input`
    display: none;

    &:checked + ${CancelReasonLabel}::after {
        display: block;
    }

    &:checked + ${CancelReasonLabel} + ${CancelReasonSolutionWrap} {
        display: block;
    }
`;


const CancelSubscriptionScreen = () => {
    const { id } = useParams();
    const { data, isFetching } = useGetSubscriptionQuery(id);
    const navigate = useNavigate();
    const [reason, setReason] = useState();
    const [cancelSubscription, { isLoading, error, isSuccess }] = useCancelSubscriptionMutation();

    const subscription = data?.subscription;
    const planFamily = data?.plan_family;
    const cancelReasons = data?.cancel_reasons;
    const productName = planFamily?.product_name || subscription?.plan?.name || 'Deleted plan';

    useEffect(() => {
        if (subscription && subscription.state !== 'active') {
            navigate(`/subscriptions/${subscription.id}`);
        }
    }, [subscription, navigate]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Subscription canceled successfully.');
            navigate(`/subscriptions/${subscription.id}`);
        }
    }, [isSuccess, navigate, subscription]);

    useEffect(() => {
        if (error) {
            toast.error('Error canceling subscription. Contact Support to cancel.');
        }
    }, [error]);

    const handleClick = useCallback(() => {
            cancelSubscription({ id: subscription.id, reason });
    }, [cancelSubscription, subscription, reason]);
    
    if (isFetching) {
        return <DashboardFrame><Loader small>Loading subscriptions</Loader></DashboardFrame>;
    }

    if (!subscription) {
        return <DashboardFrame><Message error>Subscription not found</Message></DashboardFrame>;
    }

    if (!cancelReasons) {
        return (
            <DashboardFrame breadcrumbs={[['Home', '/'], ['Subscriptions', '/subscriptions'], [productName, `/subscriptions/${subscription.id}`], ['Cancel', null]]}>
                <CancelHeading>Are you sure you want to cancel?</CancelHeading>
                <p>We're sorry to see you go.</p>
                <Flex style={{ marginTop: '30px' }}>
                    <Button black fluid as="Link" to={`/subscriptions/${subscription.id}`} disabled={isLoading}>Keep Subscription</Button>
                    <Button outline fluid onClick={handleClick} $loading={isLoading} disabled={isLoading}>Cancel Subscription</Button>
                </Flex>
            </DashboardFrame>
        )
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Subscriptions', '/subscriptions'], [productName, `/subscriptions/${subscription.id}`], ['Cancel', null]]}>
            <CancelHeading>Please tell us why you'd like to cancel your subscription</CancelHeading>
            <OptionalText>Optional</OptionalText>

            <CancelReasonRadioGroup $disabled={isLoading}>
                {(cancelReasons || []).map((reason, idx) => (
                    <CancelReason key={`reason-${idx}`}>
                        <CancelReasonRadio onClick={e => setReason(e.target.value)} type="radio" name="cancel_reason" id={`reason_${reason.code}`} value={reason.code} />
                        <CancelReasonLabel htmlFor={`reason_${reason.code}`}>{reason.text}</CancelReasonLabel>
                        <CancelReasonSolution subscriptionId={subscription.id} reason={reason} hasPlanFamily={(planFamily?.plans || []).length} />
                    </CancelReason>
                ))}
            </CancelReasonRadioGroup>
            <Flex style={{ marginTop: '30px' }}>
                <Button black fluid as="Link" to={`/subscriptions/${subscription.id}`} disabled={isLoading}>Keep Subscription</Button>
                <Button outline fluid onClick={handleClick} $loading={isLoading} disabled={isLoading}>Cancel Subscription</Button>
            </Flex>
        </DashboardFrame>
    );
};

const CancelReasonSolution = ({ reason, subscriptionId, hasPlanFamily }) => {
    if (!reason.save_strategy_code) {
        return null;
    }

    if (reason.save_strategy_code === 'change_plan' && !hasPlanFamily) {
        return null;
    }
    
    return (
        <CancelReasonSolutionWrap>
            <p>{reason.save_strategy_text}</p>
            <CancelReasonSolutionAction subscriptionId={subscriptionId} solution={reason.save_strategy_code} />
        </CancelReasonSolutionWrap>
    );
};

const CancelReasonSolutionAction = ({ solution, subscriptionId }) => {
    switch (solution) {
        case 'change_plan':
            return <Button as="Link" to={`/subscriptions/${subscriptionId}/change-plan`}>Change plan</Button>;
        case 'move_subscription':
        case 'contact_support':
            return (
                <Flex alignItems="center">
                    <Button as="a" href="mailto:support@skift.com">Contact Support</Button> 
                    <span style={{ marginLeft: '15px' }}>or email <a href="mailto:support@skift.com">support@skift.com</a></span>
                </Flex>
            );
        default:
            return null;
    }
};

export default CancelSubscriptionScreen;
