import React from 'react';
import { useGetSubscriptionQuery } from 'store/features/user-api';
import { Loader, Message } from 'ui';
import DashboardFrame from '../DashboardFrame';
import { useParams } from 'react-router-dom';

import SubscriptionDetails from './SubscriptionDetails';
import PendingChangeMessage from './PendingChangeMessage';

const SingleSubscriptionScreen = () => {
    const { id } = useParams();
    const { data, isFetching, error } = useGetSubscriptionQuery(id);

    const subscription = data?.subscription;
    const planFamily = data?.plan_family;

    const productName = planFamily?.product_name || subscription?.plan?.name || 'Deleted plan';

    if (error) {
        return <DashboardFrame><Message error>Error loading subscription</Message></DashboardFrame>;
    }

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading subscriptions</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Subscriptions', '/subscriptions'], [productName, null]]}>
            <PendingChangeMessage />
            <SubscriptionDetails subscriptionId={id} />
        </DashboardFrame>
    )
};

export default SingleSubscriptionScreen;
