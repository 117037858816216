import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from 'react-toastify';
import { useUpdateProfileMutation } from 'store/features/user-api';
import { TransactionContext, TransactionContextProvider } from "components/TransactionContext";
import ProfileForm from "components/ProfileForm/Form";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { Button, LinkButton, Loader, Message } from "ui";
import { ALL_ACCOUNT_FIELDS, DEFAULT_ACCOUNT_FIELDS } from "utils/constants";
import SummaryPane from "../SummaryPane";
import { twoPropsOrOne } from "utils/two-props-or-one";

const Properties = () => {
    const { data: profile, isFetching } = useLoggedInUser();
    
    const nameLine = useMemo(() => twoPropsOrOne(profile?.first_name, profile?.last_name, ' ', profile.email), [profile]);
    const workLine = useMemo(() => twoPropsOrOne(profile?.job, profile?.company, ' at '), [profile]);
    const locationLine = useMemo(() => twoPropsOrOne(profile?.region, profile?.country, ', '), [profile]);

    if (isFetching) {
        return <Loader small>Loading profile</Loader>;
    }

    return (
        <>
            <p>{nameLine}</p>
            {workLine && <p>{workLine}</p>}
            {locationLine && <p>{locationLine}</p>}
        </>
    );
};

const profileForEdit = profile => {
    return Object.keys(profile).reduce((a, key) => {
        if (ALL_ACCOUNT_FIELDS.includes(key)) {
            a[key] = profile[key];
        }
        return a;
    }, {});
};

const EditForm = ({ setEditing }) => {
    const { data: profile } = useLoggedInUser();
    const [nextProfile, setNextProfile] = useState(profileForEdit(profile));
    const { validators } = useContext(TransactionContext);
    const [saveProfile, { isLoading: isUpdating, error, isSuccess }] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccess) {
            toast.success('Profile saved successfully')
            setEditing(false);
        }
    }, [isSuccess, setEditing]);

    const handleFieldChange = newValues => {
        setNextProfile({
            ...nextProfile,
            ...newValues
        });
    };

    const handleSave = useCallback(e => {
        e && e.preventDefault();
        if (validators) {
            const invalid = validators.filter(valid => {
                return typeof valid === 'function' && !valid({ profile: nextProfile });
            });

            if (invalid.length) {
                window.scrollTo({
                    top: document.getElementById('edit-profile-form').offsetTop,
                    behavior: 'smooth'
                });
                return;
            };
        }

        saveProfile(nextProfile);
    }, [nextProfile, validators, saveProfile]);

    return (
        <div id="edit-profile-form">
            {error && <Message error>{error?.data?.error?.message}</Message>}
            <ProfileForm disabled={isUpdating} fields={ALL_ACCOUNT_FIELDS} requiredFields={DEFAULT_ACCOUNT_FIELDS} passedValues={nextProfile} onChange={handleFieldChange} />
            <Button loading={isUpdating} black fluid onClick={handleSave}>Save</Button>
            {!isUpdating && <p style={{ textAlign: 'center' }}><LinkButton onClick={() => setEditing(false)}>Cancel</LinkButton></p>}
        </div>
    );
};

const Profile = () => {
    const [editing, setEditing] = useState();

    return (
        <SummaryPane headingMargin="0 0 20px 0" heading="Profile" onClick={() => setEditing(!editing)} buttonText={editing ? 'Cancel' : 'Edit'} style={{ marginTop: 0 }}>
            {editing 
            ?   <TransactionContextProvider>
                    <EditForm setEditing={setEditing} />
                </TransactionContextProvider> 
            :   <Properties />}
        </SummaryPane>
    );
};

export default Profile;
