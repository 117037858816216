import React, { useEffect, useMemo, useState } from 'react';
import { useChangePlanMutation, useGetSubscriptionQuery } from 'store/features/user-api';
import { Button, Flex, Loader, Message } from 'ui';
import DashboardFrame from '../DashboardFrame';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { formatCurrency } from 'utils/formatNumber';
import { toast } from 'react-toastify';
import PendingChangeMessage from './PendingChangeMessage';

const ChangeHeading = styled.h2`
    margin-bottom: 0;
`;
const PlanWrap = styled.div`
    border: 1px solid #ddd;

    padding: 15px;
    margin: 20px 0;
    position: relative;

    ${props => props.$selected && `
        border-color: rgb(22, 125, 194);
        box-shadow: 0 0 5px rgba(22, 125, 194, 0.5);
        background-color: rgba(22, 125, 194, 0.1);
    `}

    ${props => props.$disabled && `
        opacity: 0.5;
        pointer-events: none;
    `}

    h2 {
        font-family: "acumin-pro", "Helvetica Neue", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        font-size: 23px;
    }

    h3 {
        font-family: "acumin-pro", "Helvetica Neue", sans-serif;
        font-size: 18px;
    }

    &:first-child {
        margin-top: 0;
    }
`;

const PlanDescription = styled.div`
    font-size: 15px;

    ul {
        padding: 0 0 0 20px;
    }
`;

const PlanFlag = styled.div`
    background-color:rgb(22, 125, 194);
    color: #fff;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding: 3px 10px;
    position: absolute;
    top: -25px;
`;

const CurrentPlan = styled.span`
    background-color: ${props => props.theme.yellowColor};
    color: #000;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding: 3px 10px;
    margin-left: 10px;
`;


const PlanLabel = styled.label`
    display: block;
    cursor: pointer;
    padding: 15px 50px 15px 20px;

    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
    }

    &::after {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border: 1px solid #000;
        border-radius: 50%;

        background: #000;
    }
`;

const PlanRadio = styled.input`
    display: none;

    &:checked + ${PlanLabel}::after {
        display: block;
    }

    &:checked + ${PlanLabel} {
        display: block;
    }
`;

const ChangePlanScreen = () => {
    const { id } = useParams();
    const { data, isFetching, error: errorLoadingSubscription } = useGetSubscriptionQuery(id);
    const navigate = useNavigate();
    const [changePlan, { isLoading: processingChange, error: errorChanging, isSuccess }] = useChangePlanMutation();
    
    const subscription = data?.subscription;
    const planFamily = data?.plan_family;
    const pendingChange = data?.pending_change;
    const productName = planFamily?.product_name || subscription?.plan?.name || 'Deleted plan';

    const [selectedPlan, setSelectedPlan] = useState(null);

    const currentPlan = useMemo(() => {
        if (!subscription) return null;

        if (pendingChange.next_plan) {
            return pendingChange.next_plan.id;
        }

        return subscription.plan.id;
    }, [subscription, pendingChange]);

     useEffect(() => {
        if (errorChanging) {
            toast.error('Error changing plan. Please contact support.');
        }
    }, [errorChanging]);
    
    useEffect(() => {
        if (isSuccess) {
            toast.success('Subscription changed successfully.');
            navigate(`/subscriptions/${subscription.id}`);
        }
    }, [isSuccess, navigate, subscription]);

    useEffect(() => {
        if (subscription && subscription.state !== 'active') {
            navigate(`/subscriptions/${subscription.id}`);
        }

        if (currentPlan && !selectedPlan) {
            setSelectedPlan(currentPlan);
        }
    }, [subscription, currentPlan, navigate, selectedPlan]);


    if (isFetching) {
        return <DashboardFrame><Loader small>Loading subscriptions</Loader></DashboardFrame>;
    }

    if (errorLoadingSubscription || !planFamily?.plans) {
        return <DashboardFrame><Message error>Failed to fetch plan options</Message></DashboardFrame>;
    }
    
    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Subscriptions', '/subscriptions'], [productName, `/subscriptions/${subscription.id}`], ['Change Plan', null]]}>
            <PendingChangeMessage />
            <ChangeHeading>Change Subscription</ChangeHeading>
            {planFamily.plans.map(p => (
                <Plan key={p.id} disabled={processingChange} plan={p} subscription={subscription} currentPlan={currentPlan} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
            ))}
            <Button black loading={processingChange} fluid disabled={selectedPlan === currentPlan} onClick={() => changePlan({ subscriptionId: subscription.id, planId: selectedPlan })}>Change Plan</Button>
        </DashboardFrame>
    );
};

const Plan = ({ disabled, plan, subscription, currentPlan, selectedPlan, setSelectedPlan }) => {
    const planName = useMemo(() => {
        return plan.plan.billing_frequency ? `Billed ${plan.plan.billing_frequency}` : plan.plan.name;
    }, [plan]);

    const planPrice = useMemo(() => {
        const currency = subscription.currency || 'USD';
        const price = (plan.plan.currencies || []).find(c => c.currency === currency);
        const periodLabel = (() => {
            const lengthAsNum = parseInt(plan.plan.interval_length, 10);
            if (plan.plan.interval_unit === 'months') {
                if (lengthAsNum === 1) {
                    return 'per month';
                }
                if (lengthAsNum === 3) {
                    return 'per quarter';
                }
                if (lengthAsNum === 12) {
                    return 'per year';
                }
                if (lengthAsNum === 24) {
                    return 'per 2 years';
                }
            }

            return '';
        })();
        return `${formatCurrency(price.unitAmount, currency)} ${periodLabel}`;
    }, [plan, subscription]);


    return (
        <PlanWrap $disabled={disabled} $highlighted={plan.highlighted} $selected={plan.plan.id === selectedPlan}>
            <PlanRadio checked={plan.plan.id === selectedPlan} disabled={disabled} onClick={e => setSelectedPlan(e.target.value)} type="radio" name="cancel_reason" id={`plan_${plan.plan.plan_code}`} value={plan.plan.id} />
            <PlanLabel htmlFor={`plan_${plan.plan.plan_code}`}>
                {plan.highlighted && <PlanFlag>Best Value</PlanFlag>}
                <Flex alignItems="center">
                    <h2>{planName}</h2>
                    {currentPlan === plan.plan.id && <CurrentPlan>Current&nbsp;Plan</CurrentPlan>}

                </Flex>
                <h3>{planPrice}</h3>
                <PlanDescription><ReactMarkdown>{plan.description}</ReactMarkdown></PlanDescription>
            </PlanLabel>
        </PlanWrap>
    );
};

export default ChangePlanScreen;
