import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DEFAULT_ERROR_MESSAGE } from "utils/constants";
import { Button, LinkButton, Loader, Message, OnboardingPanelBottom, OnboardingPanelContent, OnboardingPanelTop } from "ui";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useUpdateProfileMutation } from "store/features/user-api";


const SectorCloud = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
const SectorOption = styled.div`

    position: relative;
    input {
        display: none;
    }

    > label {
        display: block;
        margin: 2px 5px;

        position: relative;

        cursor: pointer;

        padding: 5px 40px 7px 15px;
        border: 1px solid #000;
        border-radius: 10px;
        font-size: 1rem;
    }
`;

const Indicator = styled.div`
    position: absolute;
    top: 50%;
    right: 15px;

    margin-top: -10px;

    width: 20px;
    height: 20px;

    label {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #000;
        z-index: 1;
        transition: all .2s;

        cursor: pointer;

        &::after, &::before {
            content: "";
            position: absolute;
            transition: all .2s;
        }

        &::before {
            left: 50%;
            top: 25%;
            height: 50%;
            border-left: 2px solid #000;
            margin-left: -1px;
        }

        &::after {
            top: 50%;
            right: 25%;
            width: 50%;
            border-bottom: 2px solid #000;
            margin-top: -1px;
        }
    }

    input:checked + label {
        border-color: #fff22f;
        background-color: #fff22f;
        transform: rotate(-45deg);

        &::before {
            height: 25%;
            left: 35%;
            top: 33%;
            margin-top: 1px;
        }

        &::after {
            width: 37.5%;
            right: 25%;
            top: 58%;
            margin-left: 1px;
        }
    }
`;

const SectorInterests = ({ next }) => {
    const { data: loggedInUser, isFetching: isFetchingProfile } = useLoggedInUser();
    const [interests, setInterests] = useState([]);
    
    const [saveProfile, { isLoading: isUpdating, error: saveError, isSuccess }] = useUpdateProfileMutation();
    
    const sectors = [
        { label: 'Airlines', value: 'airlines' },
        { label: 'Hospitality', value: 'hospitality' },
        { label: 'Online Travel', value: 'online-travel' },
        { label: 'Travel Technology', value: 'travel-tech' },
        { label: 'Tourism', value: 'tourism' }
    ];

    useEffect(() => {
        if (loggedInUser && loggedInUser.sector_interests) {
            setInterests(loggedInUser.sector_interests);
        }
    }, [loggedInUser, setInterests]);

    useEffect(() => {
        if (isSuccess) {
            next();
        }
    }, [isSuccess, next]);

    if (isFetchingProfile) {
        return <Loader $small>Loading profile</Loader>;
    }

    const handleSubmit = e => {
        e && e.preventDefault();

        saveProfile({ sector_interests: interests });
    };

    return (
        <>
            <OnboardingPanelTop>
                <h1>Let's get to know you</h1>
                <h2>Which sectors are you interested in?</h2>
            </OnboardingPanelTop>
            <OnboardingPanelContent>
                {saveError && <Message error saveError heading="Error updating profile">{saveError?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</Message>}

                <SectorCloud>
                    {sectors.map(s => (
                        <SectorOption key={s.value} style={{ marginBottom: '10px' }}>
                            <Indicator>
                                <input disabled={isUpdating} id={`sector-${s.value}`} type="checkbox" checked={interests.includes(s.value)} onChange={e => {
                                    const { checked } = e.target;
                                    if (checked) {
                                        setInterests([...interests, s.value]);
                                    } else {
                                        setInterests(interests.filter(i => i !== s.value));
                                    }
                                }} />
                                <label htmlFor={`sector-${s.value}`} />
                            </Indicator>
                            <label htmlFor={`sector-${s.value}`}>{s.label}</label>
                        </SectorOption>
                    ))}
                </SectorCloud>
            </OnboardingPanelContent>
            <OnboardingPanelBottom>
                <Button loading={isUpdating} disabled={isUpdating || interests.length === 0} size="large" black onClick={handleSubmit}>Next</Button>
                <LinkButton onClick={next} style={{ marginTop: '15px' }}>Skip</LinkButton>
            </OnboardingPanelBottom>
        </>
    )
};

export default SectorInterests;
