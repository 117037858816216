import React, { useCallback, useState } from "react";
import { FullScreenWrapper, LinkButton, OnboardingPanelWrapper, OnboardingPanel, OnboardingFooter } from "ui";
import UserProfile from './UserProfile';
import Newsletters from "./Newsletters";
import AdditionalSubscription from "./AdditionalSubscription";
import Complete from "./Complete";
import SectorInterests from "./SectorInterests";

const SubscribeOnboarding = () => {
    const [stage, setStage] = useState(0);
    const [stages, setStages] = useState([
        UserProfile, 
        Newsletters, 
        SectorInterests,
        AdditionalSubscription, 
        Complete
    ]);

    const [checkedProfileFields, setCheckedProfileFields] = useState(false);

    const next = useCallback(() => {
        setStage((prevStage) => Math.min(prevStage + 1, stages.length - 1));
    }, [stages.length]);

    const skipStage = useCallback(() => {
        setStages((prevStages) => {
            const newStages = [...prevStages];
            newStages.splice(stage, 1); // Remove current stage
            
            return newStages;
        });

        setStage((prevStage) => Math.min(prevStage, stages.length - 2)); // Adjust stage index
    }, [stage, stages.length]);

    const CurrentStage = stages[stage];

    return (
        <FullScreenWrapper>
            <OnboardingPanelWrapper>
                <OnboardingPanel $progress={(stage + 1) / stages.length}>
                    {CurrentStage ? <CurrentStage next={next} skipStage={skipStage} checkedProfileFields={checkedProfileFields} setCheckedProfileFields={setCheckedProfileFields} /> : null}
                </OnboardingPanel>
                {stage > 0 && <OnboardingFooter><LinkButton onClick={() => setStage(stage - 1)}>Go Back</LinkButton></OnboardingFooter>}
            </OnboardingPanelWrapper>
        </FullScreenWrapper>
    );
};

export default SubscribeOnboarding;
