import React, { useEffect } from "react";
import { DEFAULT_ACCOUNT_FIELDS } from "utils/constants";
import { Panel } from "ui";

import Form from "./Form";
import { useLoggedInUser } from "hooks/useLoggedInUser";

const fieldsToFill = (fields, profile = {}) => {
    return fields.filter(f => !profile[f] || profile[f] === '');
};

const ProfileForm = ({ onProfileCompleted, fields = DEFAULT_ACCOUNT_FIELDS, showContinueBtn = true, editAll = false, ...props }) => {
    const { data: loggedInUser, isFetching: isFetchingProfile } = useLoggedInUser();
    fields = editAll ? fields : fieldsToFill(fields, loggedInUser);

    useEffect(() => {
        if (fields.length === 0) {
            typeof onProfileCompleted === 'function' && onProfileCompleted(true);
        }
    }, [fields, onProfileCompleted]);

    if (fields.length === 0 || isFetchingProfile) {
        return null;
    }

    return (
        <Panel id="profile-form" padded {...props}>
            <h2>{Object.keys(loggedInUser || {}).length > 0 ? 'We need some more information' : 'Tell us about yourself'}</h2>
            <Form fields={fields} onProfileCompleted={onProfileCompleted} showContinueBtn={showContinueBtn} />
        </Panel>
    );
};

export default ProfileForm;
