import styled from "styled-components";

export const FormGroup = styled.div`
    position: relative;
    font-size: 0.9rem;
    background-color: inherit;

    margin: 20px 0;

    &:first-child {
        margin-top: 5px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
