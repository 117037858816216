import React, { useEffect, useMemo, useState } from "react";
import Fields from "components/ProfileForm/Fields";
import { DEFAULT_ACCOUNT_FIELDS, DEFAULT_ERROR_MESSAGE } from "utils/constants";
import { Button, Errors, LinkButton, Loader, Message, OnboardingPanelBottom, OnboardingPanelContent, OnboardingPanelTop } from "ui";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useProfileValidation } from "hooks/useProfileValidation";
import { useUpdateProfileMutation } from "store/features/user-api";

const emptyFields = (fields, profile = {}) => {
    return fields.filter(f => !profile[f] || profile[f].trim() === '');
};

const Profile = ({ next, skipStage, checkedProfileFields, setCheckedProfileFields }) => {
    const { data: loggedInUser, isFetching: isFetchingProfile } = useLoggedInUser();
    const [profile, setProfile] = useState({});
    
    const fields = DEFAULT_ACCOUNT_FIELDS;
    const [validationErrors, validate, validationRan] = useProfileValidation(['first_name', 'last_name', 'company'], fields);
    const [saveProfile, { isLoading: isUpdating, error: saveError, isSuccess }] = useUpdateProfileMutation();
    
    useEffect(() => {
        if (checkedProfileFields || !loggedInUser) {
            return;
        }

        if (emptyFields(fields, loggedInUser).length === 0) {
            skipStage();
        }

        setCheckedProfileFields(true);
    }, [loggedInUser, fields, skipStage, checkedProfileFields, setCheckedProfileFields]);

    useEffect(() => {
        if (loggedInUser) {
            const nextProfile = fields.reduce((a, key) => {
                const value = loggedInUser[key];
                if (value) {
                    a[key] = value;
                }
                return a;
            }, {});
            setProfile(nextProfile);
        }
    }, [loggedInUser, fields]);

    useEffect(() => {
        if (isSuccess) {
            next();
        }
    }, [isSuccess, next]);


    const filledOutFields = useMemo(() => {
        return fields.filter(f => profile[f] && profile[f].trim() !== '');
    }, [fields, profile]);

    const handleFieldChange = (keyOrChanges, val) => {

        // the first param can be a string of a single property or an object of 
        // properties that can be updated all at once

        let updates = keyOrChanges;

        if (typeof keyOrChanges === 'string') {
            updates = { [keyOrChanges]: val };
        }
    
        const nextProfile = {
            ...profile,
            ...updates
        };

        if (validationRan) {
            validate({ profile: nextProfile });
        }

       setProfile(nextProfile);
    };

    const handleSubmit = e => {
        e && e.preventDefault();

        const valid = validate({ profile });

        if (valid) {
            saveProfile(profile);
        }

        return valid;
    };

    if (fields.length === 0 || isFetchingProfile) {
        return <Loader $small>Loading profile</Loader>;
    }

    return (
        <>
            <OnboardingPanelTop>
                <h1>Just a few more things</h1>
                <h2>Help us personalize your Skift experience by filling in the following details</h2>
            </OnboardingPanelTop>
            <OnboardingPanelContent>
                <Errors errors={validationErrors} />
                {saveError && <Message error saveError heading="Error updating profile">{saveError?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</Message>}
                <Fields disabled={isUpdating} fields={fields} values={profile} onFieldChange={handleFieldChange} />
            </OnboardingPanelContent>
            <OnboardingPanelBottom>
                <Button loading={isUpdating} disabled={isUpdating || filledOutFields.length === 0} size="large" black onClick={handleSubmit}>Next</Button>
                <LinkButton onClick={next} style={{ marginTop: '15px' }}>Skip</LinkButton>
            </OnboardingPanelBottom>
        </>
    )
};

export default Profile;
