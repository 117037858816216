import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Crumbs = styled.ul`
    padding: 0;
    list-style: none;
    display: flex;

    flex-wrap: wrap;
    margin: 0;

    @media screen and (min-width: 800px) {
        position: absolute;
        left: 0;
        top: -31px;
    }
    @media screen and (max-width: 800px) {
        margin: 0 0 20px 0;
    }

    li {
        margin-right: 15px;
        padding-right: 15px;
        position: relative;
        font-size: 14px;

        &::after {
            content: '';
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            width: 5px;
            height: 5px;
            position: absolute;
            top: 8px;
            right: 0;
            transform: rotate(-45deg);
        }

        &:last-child::after {
            display: none;
        }
        
        a {
            text-decoration: none;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }
    }
`;

const Breadcrumbs = ({ breadcrumbs }) => {
    const panko = useMemo(() => {
        if (!breadcrumbs) {
            return [];
        }

        return breadcrumbs.map(([label, link], idx) => link ? <Link to={link} key={`panko-crumb-${idx}`}>{label}</Link> : <span key={`panko-crumb-${idx}`}>{label}</span>);
    }, [breadcrumbs]);

    if (!breadcrumbs) {
        return null;
    }

    return (
        <Crumbs>
            {panko.map((p, idx) => <li key={`panko-group-${idx}`}>{p}</li>)}
        </Crumbs>
    );
};

export default Breadcrumbs;
