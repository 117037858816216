import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetSubscriptionQuery, useReactivateSubscriptionMutation } from 'store/features/user-api';
import { Button, Message } from 'ui';

const ReactivateMessage = () => {
    const { id } = useParams();
    const { data } = useGetSubscriptionQuery(id);

    const subscription = data?.subscription;
    const planFamily = data?.plan_family;

    const renewIncentiveEnabled = planFamily?.reactivate_incentive_heading && planFamily?.reactivate_incentive_text;
    const alreadyUsed = data.has_used_incentive;

    const heading = planFamily?.reactivate_incentive_heading;
    const text = planFamily?.reactivate_incentive_text;

    const canceled = subscription?.state === 'canceled';

    const [go, { isLoading, error }] = useReactivateSubscriptionMutation();

    useEffect(() => {
        if (error) {
            const msg = canceled ? 'Error reactivating subscription.' : 'Error enabling auto-renew.';
            toast.error(msg + ' Please contact Support.');
        }
    }, [error, canceled]);

    const handleClick = useCallback(() => {
        const msg = canceled ? 'reactivate' : 'enable auto-renew for';
        if (window.confirm(`Are you sure you want to ${msg} this subscription?`)) {
            go(subscription.id);
        }
    }, [go, subscription, canceled]);

    if (!renewIncentiveEnabled || alreadyUsed) return null;

    return (
        <Message light jumbotron heading={heading}>
            <p>{text}</p>
            <Button loading={isLoading} fluid onClick={handleClick}>{canceled ? 'Reactivate' : 'Enable Auto-Renew'}</Button>
        </Message>
    );
};

export default ReactivateMessage;
