import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    margin: 15px 0;
    padding: 15px 20px;

    border: 1px solid #000;
    border-radius: 10px;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    img {
        max-height: 50px;
        max-width: 200px;
    }

    h3 {
        margin: 0;
    }

    .price {
        margin: 5px 0 0 0;
        font-weight: 600;
    }

    .regular-price {
        margin: 5px 0 0 0;
        text-decoration: line-through;
        font-size: 0.9rem;
    }

    .desc {
        margin: 5px 0 15px;
        font-size: 0.95rem;
    }

    @media screen and (max-width: 500px) {
        a {
            margin-top: 10px;
        }
        .desc {
            display: none;
        }
    }
`;

const Site = ({ logo_url, name, description, PriceLine, CTA }) => {
    return (
        <Wrap>
            {logo_url ? <img src={logo_url} alt={name} /> : <h3>{name}</h3>}
            {PriceLine && <PriceLine />}
            <p className="desc">{description}</p>
            {CTA && <CTA />}
        </Wrap>
    )

};

export default Site;
