import styled from 'styled-components';

export const OnboardingPanelWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    padding: 15px 15px;

    @media screen and (max-width: 500px) {
        justify-content: flex-start;
    }
`;

export const OnboardingPanel = styled.div`
    text-align: center;
    width: 525px;
    max-width: 100%;
    max-height: calc(100vh - 40px - 40px);

    padding: ${props => props.theme.gutterSize};

    background-color: #fff;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%);

    position: relative;

    display: flex;
    flex-direction: column;

    &::before {
        content: '';
        display: block;
        width: ${props => props.$progress * 100}%;
        height: 5px;
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.3s ease;
    }
`;

export const OnboardingPanelTop = styled.div`
    h1 {
        font-size: 2.3rem;
        font-weight: bold;
        margin: 0 0 10px 0;
    }

    h2 {
        font-size: 1.3rem;
        margin: 0;
    }

    @media screen and (max-width: 500px) {
        h1 {
            font-size: 1.7rem;
        }

        h2 {
            font-size: 1.2rem;
        }
    }
`;
export const OnboardingPanelBottom = styled.div`
    display: flex;
    flex-direction: column;
`;
export const OnboardingPanelContent = styled.div`
    overflow-y: auto;
    flex-grow: 1;
    margin: 20px 0;
`;

export const OnboardingFooter = styled.div`
    height: 25px;
    margin-top: 15px;
`;