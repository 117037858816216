import React from "react";
import { Button, Loader, OnboardingPanelBottom, OnboardingPanelContent, OnboardingPanelTop } from "ui";
import { Link } from "react-router-dom";
import { useContentItem } from "hooks";
import Site from "./Site";

const Complete = () => {
    const { data: item, isFetching } = useContentItem();
    const sites = item?.details?.site_access || [];

    if (isFetching) {
        return <Loader $small>Loading purchase</Loader>;
    }

    if (sites.length) {
        return (
            <>
                <OnboardingPanelTop>
                    <h1>Thank you</h1>
                    <p>You have unlocked the following product{sites.length > 1 && 's'}:</p>
                </OnboardingPanelTop>
                <OnboardingPanelContent>
                    {sites.map(s => <Site key={`prod-${s.id}`} {...s} CTA={() => <Button black as="a" href={s.start_reading}>Start Reading</Button>} />)}
                </OnboardingPanelContent>
                <OnboardingPanelBottom>
                    <Link to="/">View your Skift dashboard</Link>
                </OnboardingPanelBottom>
            </>
        );
    }

    return (
        <>
            <OnboardingPanelTop>
                <h1>Thank you</h1>
                <p>Click the link below to view your dashboard</p>
            </OnboardingPanelTop>
            <OnboardingPanelBottom>
                <Link to="/">View your Skift dashboard</Link>
            </OnboardingPanelBottom>
        </>
    );
};

export default Complete;
