import { Link } from "react-router-dom";
import styled from "styled-components";

export const ActionList = styled.div`

`;

const ActionItemBase = as => styled(as)`
    text-decoration: none;
    margin: 20px 0;
    display: block;

    position: relative;
    padding-right: 30px;

    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;

    text-align: left;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 3px;
        transform: translateY(-50%) rotate(45deg);

        width: 15px;
        height: 15px;
        border-top: 2px solid rgba(0, 0, 0, 0.6);
        border-right: 2px solid rgba(0, 0, 0, 0.6);
    }
`;

export const ActionItem = ActionItemBase(Link);
export const ActionItemButton = ActionItemBase('button');

export const ActionItemLabel = styled.p`
    margin: 0 0 5px 0;
    font-size: 16px !important;
`;

export const ActionItemText = styled.p`
    margin: 0;
    opacity: 0.6;
`;

export const ActionListSeparator = styled.div`
    height: 0;
    border-bottom: 1px solid #b1b1b1;
`;
