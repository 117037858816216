import React, { useContext, useEffect, useMemo, useState } from "react";
import { useRecurly, CardElement } from '@recurly/react-recurly';
import { TextField, SelectField, PostalCodeField, Errors } from 'ui';
import { countryData, regionData } from 'shared/country-region-data';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingAddress } from 'store/features/transaction';
import AmexWarning from "./AmexWarning";
import AcceptedCardBrands from "./AcceptedCardTypes";
import { TransactionContext } from "components/TransactionContext";
import { useRecurlyCardValidation } from "hooks/useRecurlyCardValidation";

const CardForm = ({ disabled, passedValues, onChange }) => {
    const formRef = React.useRef();
    const billingAddress = useSelector(state => state.transaction.billingAddress);
    const [errors, validate, validationRan] = useRecurlyCardValidation();

    const { setTokenize, processing, addValidator, removeValidator } = useContext(TransactionContext);

    const dispatch = useDispatch();
    const recurly = useRecurly();
    const [cardType, setCardType] = useState();

    // map the courties so we send the country ISO code to recurly
    const countryOptions = useMemo(() => {
        return countryData.map(c => ({
            ...c,
            value: c.key
        }));
    }, []);

    const values = useMemo(() => {
        return passedValues || billingAddress;
    }, [passedValues, billingAddress]);

    const selectedCountry = useMemo(() => {
        return values?.billing_country ? countryData.find(c => c.key === values.billing_country) : null;
    }, [values?.billing_country]);

    useEffect(() => {
        if (recurly && formRef) {
            setTokenize(() => {
                return new Promise((resolve, reject) => {
                    recurly.token(formRef.current, (err, { id: token } = {}) => {
                        if (err) {
                            reject(err);
                        }
                        resolve(token);
                    });
                });
            });
        }

        return function cleanup() {
            setTokenize(null);
        };
    }, [recurly, formRef, setTokenize]);

    useEffect(() => {
        addValidator('recurly_billing', validate);

        return function cleanup() {
            removeValidator('recurly_billing');
        };
    }, [addValidator, validate, removeValidator]);

    const maybeValidate = nextValues => {
        if (validationRan) {
            validate({ billingAddress: { ...values, ...nextValues } });
        }
    };

    const handleFieldChange = e => {
        const nextValues = { [e.target.name]: e.target.value };

        onChange ? onChange(nextValues) : dispatch(setBillingAddress(nextValues));

        maybeValidate(nextValues);
    };

    const handlePostalChange = values => {
        const nextValues = { billing_zip: values.zip };

        if(values.country) nextValues.billing_country = values.country;
        if(values.region) nextValues.billing_region = values.region;

        onChange ? onChange(nextValues) : dispatch(setBillingAddress(nextValues));

        maybeValidate(nextValues);
    };

    const handleCardChange = state => {
        const nextValues = { card_valid: state.valid };

        onChange ? onChange(nextValues) : dispatch(setBillingAddress(nextValues));

        const brand = state?.brand;
        if (brand && cardType !== brand) {
            setCardType(brand);
        }

        maybeValidate(nextValues);
    };

    return (
        <form ref={formRef}>
            <AcceptedCardBrands />
            <Errors errors={errors} />
            <TextField
                data-recurly="first_name"
                label="First Name on Card"
                name="billing_first_name"
                value={values.billing_first_name || ''}
                onChange={handleFieldChange}
                disabled={processing || disabled}
            />
            <TextField
                data-recurly="last_name"
                label="Last Name on Card"
                name="billing_last_name"
                value={values.billing_last_name || ''}
                onChange={handleFieldChange}
                disabled={processing || disabled}
            />
            <PostalCodeField
                data-recurly="postal_code"
                label="Postal Code"
                name="billing_zip"
                value={values.billing_zip || ''}
                onChange={handlePostalChange}
                disabled={processing || disabled}
            />
            <SelectField 
                data-recurly="country"
                label="Country" 
                name="billing_country" 
                value={values.billing_country || ''}
                onChange={handleFieldChange}
                options={countryOptions}
                disabled={processing || disabled}
            />
            <SelectField 
                data-recurly="billing_state"
                label="Region" 
                name="billing_region" 
                value={values.billing_region || ''}
                onChange={handleFieldChange}
                disabled={processing || disabled}
                options={selectedCountry ? regionData[selectedCountry.text] : null}
            />
            <AmexWarning cardType={cardType} />
            <CardElement style={{
                fontSize: '14px'
            }} onChange={handleCardChange} />
        </form>
    );
};

export default CardForm;
