import { useSelector } from 'react-redux';

import { useGetItemDetailsQuery } from 'store/features/content-api';
import { useAuth0 } from 'components/auth0/Auth0Context';

export const useContentItem = () => {
    const { isAuthenticated } = useAuth0();
    
    const itemId = useSelector(state => state.transaction.itemId);
    const transactionType = useSelector(state => state.transaction.type);
    const group = useSelector(state => state.transaction.groupCode);

    const hookResult = useGetItemDetailsQuery({ itemId, transactionType, group, isAuthenticated }, { skip: !itemId || !transactionType });

    if (!transactionType) {
        return { isLoading: true, isFetching: true };
    }

    return hookResult;
};
