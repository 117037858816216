import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useGetNewsletterPreferencesQuery, useUpdateNewsletterPreferencesMutation } from "store/features/user-api";
import { Button, LinkButton, Loader, Message, OnboardingPanelBottom, OnboardingPanelContent, OnboardingPanelTop } from "ui";
import { DEFAULT_ERROR_MESSAGE } from "utils/constants";

const Newsletter = styled.div`
    border: 1px solid #000;
    margin-bottom: 15px;

    position: relative;
    border-radius: 10px;

    text-align: left;

    h3, p {
        margin: 0;
    }

    h3 {
        font-weight: bold;
        margin-bottom: 5px;
    }

    p {
        font-size: 0.9rem;
    }

    input {
        display: none;
    }

    .nl-details {
        display: block;
        padding: 15px 55px 15px 20px;
        cursor: pointer;
    }
`;


const Indicator = styled.div`
    position: absolute;
    top: 50%;
    right: 15px;

    margin-top: -15px;

    width: 30px;
    height: 30px;

    label {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #000;
        z-index: 1;
        transition: all .2s;

        cursor: pointer;

        &::after, &::before {
            content: "";
            position: absolute;
            transition: all .2s;
        }

        &::before {
            left: 50%;
            top: 25%;
            height: 50%;
            border-left: 2px solid #000;
            margin-left: -1px;
        }

        &::after {
            top: 50%;
            right: 25%;
            width: 50%;
            border-bottom: 2px solid #000;
            margin-top: -1px;
        }
    }

    input:checked + label {
        border-color: #fff22f;
        background-color: #fff22f;
        transform: rotate(-45deg);

        &::before {
            height: 25%;
            left: 35%;
            top: 33%;
            margin-top: 1px;
        }

        &::after {
            width: 37.5%;
            right: 25%;
            top: 58%;
            margin-left: 1px;
        }
    }
`;

const Newsletters = ({ next, skipStage }) => {
    const { data: prefs, isFetching, error } = useGetNewsletterPreferencesQuery();
    const [savePrefs, { isLoading: isUpdating, error: updateError, isSuccess }] = useUpdateNewsletterPreferencesMutation(); 
    const [nextPrefs, setNextPrefs] = useState();
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            next();
        }
    }, [isSuccess, next]);

    useEffect(() => {
        if (!nextPrefs && prefs) {
            // check if all newsletters are subscribed
            const allSubscribed = prefs.every(({ value }) => value);
            if (allSubscribed) {
                skipStage();
            }

            setNextPrefs(prefs.reduce((a, { newsletter_code, value }) => {
                a[newsletter_code] = value;
                return a;
            }, {}));
        }
    }, [nextPrefs, prefs, skipStage]);

    const handleFieldChange = useCallback(e => {
        const next = { [e.target.name]: e.target.checked };
        setNextPrefs({
            ...nextPrefs,
            ...next
        });
    }, [nextPrefs]);

    const prefsToDisplay = useMemo(() => {
        if (!prefs) {
            return [];
        }
        if (showAll) {
            return prefs;
        }
        // show the first 5 newsletters
        return prefs.slice(0, 5);
    }, [prefs, showAll]);

    const selectedPrefs = useMemo(() => {
        if (!nextPrefs) {
            return [];
        }
        return Object.entries(nextPrefs).filter(([_, value]) => value).map(([key]) => key);
    }, [nextPrefs]);

    const handleSubmit = useCallback(e => {
        savePrefs(nextPrefs);

        e.preventDefault();
    }, [nextPrefs, savePrefs]);

    if (error) {
        return <Message error heading="Error loading newsletters">{error?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</Message>;
    }

    if (updateError) {
        return <Message error heading="Error updating newsletter preferences">{updateError?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</Message>;
    }

    if (isFetching || !nextPrefs) {
        return <Loader $small>Loading newsletters</Loader>;
    }

    return (
        <>
            <OnboardingPanelTop>
                <h1>Never miss a beat</h1>
                <h2>Subscribe to our newsletters to stay up to date with the topics that matter most to you</h2>
            </OnboardingPanelTop>
            <OnboardingPanelContent>
                {prefsToDisplay.map((newsletter) => (
                    <NewsletterItem key={`nl-${newsletter.newsletter_code}`} {...newsletter} checked={nextPrefs[newsletter.newsletter_code]} onChange={handleFieldChange} disabled={isUpdating} />
                ))}
                {!showAll && prefs.length > 5 && <LinkButton onClick={() => setShowAll(true)}>See more newsletters</LinkButton>}
            </OnboardingPanelContent>
            <OnboardingPanelBottom>
                <Button loading={isUpdating} disabled={isUpdating || selectedPrefs.length === 0} size="large" black onClick={handleSubmit}>Subscribe</Button>
                <LinkButton onClick={next} style={{ marginTop: '15px' }}>Skip</LinkButton>
            </OnboardingPanelBottom>
        </>
    )
};

const NewsletterItem = ({ newsletter_code, name, description, checked, onChange, disabled }) => {
    return (
        <Newsletter>
            <Indicator>
                <input id={newsletter_code} type="checkbox" checked={checked} name={newsletter_code} onChange={onChange} disabled={disabled} />
                <label htmlFor={newsletter_code} />
            </Indicator>
            <label className="nl-details" htmlFor={newsletter_code}>
                <h3>{name}</h3>
                <p>{description}</p>
            </label>
        </Newsletter>
    )
};

export default Newsletters;
