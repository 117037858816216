import { useParams } from "react-router-dom";
import { useGetSubscriptionQuery } from "store/features/user-api";
import { Message } from "ui";

const PendingChangeMessage = () => {
    const { id } = useParams();
    const { data } = useGetSubscriptionQuery(id);

    const pendingChange = data?.pending_change;

    if (!pendingChange || !pendingChange.next_plan) {
        return null;
    }

    return (
        <Message info heading="Pending Change">
            Your change to <strong>{pendingChange.next_plan.name}</strong> will go into effect on {new Date(pendingChange.activate_at).toLocaleDateString()}.
        </Message>
    );
};

export default PendingChangeMessage;
