import { useCallback, useState } from "react";
import { ACCOUNT_PROPERTIES } from "shared/account-constants";
import { isValidPhoneNumber } from "react-phone-number-input";

export const useProfileValidation = (requiredFields) => {
    const [errors, setErrors] = useState([]);
    const [validationRan, setValidationRan] = useState(false);

    const requiredProps = ACCOUNT_PROPERTIES.filter((p) => requiredFields.includes(p.key));
    const allProps = ACCOUNT_PROPERTIES.reduce((acc, prop) => {
        acc[prop.key] = prop.text;
        return acc;
    }, {});

    const validate = useCallback(({ profile: values }) => {
        const nextErrors = [];

        // Validate required fields
        requiredProps.forEach(({ key, text }) => {
            if (!values[key]?.trim()) {
                nextErrors.push({ field: key, text: `${text} is required` });
            }
        });

        // Validate all provided values
        Object.entries(values).forEach(([key, rawValue]) => {
            const value = (rawValue || '').trim();
            const text = allProps[key];
            if (!text) return; // Skip unknown properties

            if (value && value.length < 2) {
                nextErrors.push({ field: key, text: `${text} must be at least 2 characters long` });
            }
            if (key !== "industry" && value && value.length > 50) {
                nextErrors.push({ field: key, text: `${text} must be less than 50 characters long` });
            }
            if (key === "phone" && value && !isValidPhoneNumber(value)) {
                nextErrors.push({ field: key, text: `${text} must be a valid phone number` });
            }
        });

        setErrors(nextErrors);
        setValidationRan(true);
        return nextErrors.length === 0;
    }, [requiredProps, allProps]);

    return [errors, validate, validationRan];
};
