import React, { useEffect } from "react";
import { DEFAULT_ERROR_MESSAGE } from "utils/constants";
import { Button, LinkButton, Loader, Message, OnboardingPanelBottom, OnboardingPanelContent, OnboardingPanelTop } from "ui";
import { useGetOnboardingPlansQuery } from "store/features/user-api";
import { formatCurrency } from "utils/formatNumber";
import Site from "./Site";
import { useSelector } from "react-redux";

const AdditionalSubscription = ({ next, skipStage }) => {
    const { data, error, isFetching } = useGetOnboardingPlansQuery();
    const currency = useSelector(state => state.transaction.currency) || 'USD';

    useEffect(() => {
        if (data && (data.sites || []).length === 0) {
            skipStage();
        }
    }, [data, skipStage]);

    if (isFetching) {
        return <Loader $small>Loading subscriptions</Loader>;
    }

    if (error) {
        return <Message error heading="Error loading subscriptions">{error?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</Message>;
    }

    const couponCodeParam = data?.coupon?.code ? `promo=${data.coupon.code}` : "";
    const heading = data?.coupon?.discount?.percent ? `Take ${data.coupon.discount.percent}% off any other Skift subscription` : "Subscribe to more Skift subscriptions";
    const discountDecimal = data?.coupon?.discount?.percent ? data.coupon.discount.percent / 100 : 0;

    return (
        <>
            <OnboardingPanelTop>
                <h1>One last step</h1>
                <h2>{heading}</h2>
            </OnboardingPanelTop>

            <OnboardingPanelContent>
                {(data?.sites || []).map(site => {
                    let siteCurrency = currency;
                    let regPrice = site.primary_plan.currencies.find(c => c.currency === currency)?.unitAmount;

                    if (!regPrice && currency !== 'USD') {
                        siteCurrency = 'USD';
                        regPrice = site.primary_plan.currencies.find(c => c.currency === 'USD')?.unitAmount;
                    }

                    const currencyParam = siteCurrency === 'USD' ? '' : `currency=${siteCurrency}`;
                    const querystring = [couponCodeParam, currencyParam].filter(Boolean).join('&');

                    const discountPrice = regPrice - (regPrice * discountDecimal);

                    const billingFreq = (() => {
                        if (site.primary_plan.interval_unit === 'months' && site.primary_plan.interval_length === "1") {
                            return 'per month';
                        }
                        if (site.primary_plan.interval_unit === 'months' && site.primary_plan.interval_length === "3") {
                            return 'per quarter';
                        }
                        if (site.primary_plan.interval_unit === 'months' && site.primary_plan.interval_length === "12") {
                            return 'per year';
                        }
                        if (site.primary_plan.interval_unit === 'months' && site.primary_plan.interval_length === "24") {
                            return 'every 2 years';
                        }

                        return '';
                    })();

                    const PriceLine = () => (
                        <>
                            <p className="price">{formatCurrency(discountPrice, siteCurrency)} {billingFreq}</p>
                            {regPrice !== discountPrice && <p className="regular-price">{formatCurrency(regPrice, siteCurrency)} {billingFreq}</p>}
                        </>
                    );
                
                    return (
                        <Site 
                            key={site.id} 
                            {...site}
                            PriceLine={PriceLine} 
                            CTA={() => <Button as="a" black href={`/subscribe/${site.primary_plan.plan_code}?${querystring}`}>Subscribe</Button>}
                        />
                    );
                })}
            </OnboardingPanelContent>
            
            <OnboardingPanelBottom>
                <LinkButton size="large" black onClick={next} style={{ marginTop: '10px' }}>Skip</LinkButton>
            </OnboardingPanelBottom>
        </>
    )
};

export default AdditionalSubscription;
