import React, { useCallback, useEffect } from 'react';
import { formatCurrency } from 'utils/formatNumber';
import moment from 'moment';
import { ucFirst } from 'utils/ucFirst';
import SummaryPane from "../SummaryPane";
import { Stat, Stats, ActionList, ActionItem, ActionItemLabel, ActionItemText, ActionListSeparator, Loader, ActionItemButton } from 'ui';
import { useGetSubscriptionQuery, useReactivateSubscriptionMutation } from 'store/features/user-api';
import ReactivateMessage from './ReactivateMessage';
import { toast } from 'react-toastify';

const SubscriptionDetails = ({ subscriptionId }) => {
    const { data, isFetching, error } = useGetSubscriptionQuery(subscriptionId);

    const subscription = data?.subscription;
    const nextInvoice = data?.next_invoice;

    if (error) {
        return <div>Error loading subscription</div>;
    }
    if (isFetching) {
        return <Loader small>Loading subscription</Loader>;
    }

    return (
        <>
            <SummaryPane headingMargin="0 0 20px 0" heading="Your subscription" disabled style={{ marginTop: 0 }}>
                {((subscription.state === 'active' && !subscription.auto_renew) || subscription.state === 'canceled') && (
                    <ReactivateMessage />
                )}

                <Stats style={{ flexDirection: 'column' }}>
                    <Stat label="Subscription plan">
                        {subscription.plan?.name || 'Deleted plan'}
                    </Stat>

                    <Stat label="Subscription status">
                        {ucFirst(subscription.state)}
                    </Stat>

                    {nextInvoice && (
                        <Stat label="Next payment">
                            {formatCurrency(nextInvoice.total, nextInvoice.currency)} on {moment(nextInvoice.date).format('MM/DD/Y h:mm a')}
                        </Stat>
                    )}

                    {((subscription.state === 'active' && !subscription.auto_renew) || subscription.state === 'canceled') && (
                        <Stat label="Subscription ends">
                            {moment(subscription.current_period_ends_at).format('MM/DD/Y h:mm a')}
                        </Stat>
                    )}

                </Stats>

            </SummaryPane>
            {subscription.state === 'active' && <SummaryPane heading="Manage your subscription" disabled>
                <ActionList>
                    <ActionItem to={`/subscriptions/${subscription.id}/change-plan`}>
                        <ActionItemLabel>Change your subscription</ActionItemLabel>
                        <ActionItemText>See if another plan better suits your needs.</ActionItemText>
                    </ActionItem>
                    <ActionListSeparator />
                    <ActionItem to={`/subscriptions/${subscription.id}/cancel`}>
                        <ActionItemLabel>Cancel your subscription</ActionItemLabel>
                    </ActionItem>
                </ActionList>
            </SummaryPane>}

            {subscription.state === 'canceled' && <SummaryPane heading="Manage your subscription" disabled>
                <ActionList>
                    <ReactivateAction subscriptionId={subscription.id} />
                </ActionList>
            </SummaryPane>}
        </>
    );
};

const ReactivateAction = ({ subscriptionId }) => {
    const [go, { isLoading, error }] = useReactivateSubscriptionMutation();

    useEffect(() => {
        if (error) {
            toast.error('Error reactivating subscription. Please contact Support.');
        }
    }, [error]);

    const handleClick = useCallback(() => {
        if (window.confirm('Are you sure you want to reactivate this subscription?')) {
            go(subscriptionId);
        }
    }, [go, subscriptionId]);

    return (
        <ActionItemButton loading={isLoading} disabled={isLoading} onClick={handleClick}>
            <ActionItemLabel>Reactivate your subscription</ActionItemLabel>
        </ActionItemButton>
    );
};

export default SubscriptionDetails;
